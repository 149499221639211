import axios from "axios";
import { request, apiRequestPending, apiRequestReady } from "../api/actions";
import * as Sentry from "@sentry/react";

export const RECEIVED_IMAGE_LIST_SUCCESS = "RECEIVED_IMAGE_LIST_SUCCESS";
export const RECEIVED_IMAGE_LIST_ERROR = "RECEIVED_IMAGE_LIST_ERROR";

export const RECEIVED_COLLECTIONS_SUCCESS = "RECEIVED_COLLECTIONS_SUCCESS";
export const RECEIVED_COLLECTIONS_ERROR = "RECEIVED_COLLECTIONS_ERROR";

export const COLLECTION_UPDATE_SUCCESS = "COLLECTION_UPDATE_SUCCESS";
export const COLLECTION_UPDATE_ERROR = "COLLECTION_UPDATE_ERROR";

export const COLLECTION_DELETE_SUCCESS = "COLLECTION_DELETE_SUCCESS";
export const COLLECTION_DELETE_ERROR = "COLLECTION_DELETE_ERROR";

export const COLLECTION_ADD_SUCCESS = "COLLECTION_ADD_SUCCESS";
export const COLLECTION_ADD_ERROR = "COLLECTION_ADD_ERROR";

export const IMAGE_UPLOADED = "IMAGE_UPLOADED";
export const IMAGE_DELETE_SUCCESS = "IMAGE_DELETE_SUCCESS";
export const IMAGE_DELETE_ERROR = "IMAGE_DELETE_ERROR";

export const getImageListSuccess = result => ({
  type: RECEIVED_IMAGE_LIST_SUCCESS,
  payload: result
});

export const getImageListError = err => ({
  type: RECEIVED_IMAGE_LIST_ERROR,
  payload: err
});

export const fetchImageList = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "getAdminImages",
    {},
    getImageListSuccess,
    getImageListError
  );
};

export const getCollectionsSuccess = result => ({
  type: RECEIVED_COLLECTIONS_SUCCESS,
  payload: result
});

export const getCollectionsError = err => ({
  type: RECEIVED_COLLECTIONS_ERROR,
  payload: err
});

export const fetchCollections = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "getAdminCollections",
    {},
    getCollectionsSuccess,
    getCollectionsError
  );
};

export const updateCollectionSuccess = result => ({
  type: COLLECTION_UPDATE_SUCCESS,
  payload: result
});

export const updateCollectionError = err => ({
  type: COLLECTION_UPDATE_ERROR,
  payload: err
});

export const updateCollection = collection => async (dispatch, getState) => {
  request(dispatch, getState)(
    "updateAdminCollection",
    {
      data: collection,
      pathParams: {
        collectionId: collection.id
      }
    },
    updateCollectionSuccess,
    updateCollectionError
  );
};

export const addCollectionSuccess = result => ({
  type: COLLECTION_ADD_SUCCESS,
  payload: result
});

export const addCollectionError = err => ({
  type: COLLECTION_ADD_ERROR,
  payload: err
});

export const addCollection = (type, title) => async (dispatch, getState) => {
  request(dispatch, getState)(
    "addAdminCollection",
    {
      data: {
        type: type,
        title: title
      }
    },
    addCollectionSuccess,
    addCollectionError
  );
};

export const deleteCollectionSuccess = result => ({
  type: COLLECTION_DELETE_SUCCESS,
  payload: result
});

export const deleteCollectionError = err => ({
  type: COLLECTION_DELETE_ERROR,
  payload: err
});

export const deleteCollection = collectionId => async (dispatch, getState) => {
  request(dispatch, getState)(
    "deleteAdminCollection",
    {
      pathParams: {
        collectionId: collectionId
      }
    },
    deleteCollectionSuccess,
    deleteCollectionError
  );
};

export function uploadImage(file, collection) {
  return async function(dispatch, getState) {
    try {
      const requestId = "uploadAdminImage";

      dispatch(apiRequestPending(requestId));

      const response = await axios("api/admin/images", {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          "x-access-token": getState().user.token
        },
        data: {
          fileName: file.name,
          fileType: file.type
        }
      });

      const config = {
        headers: {
          "Content-Type": file.type
        }
      };

      await axios.put(response.data.signedRequest, file, config);

      if (collection) {
        const newCollection = Object.assign({}, collection);

        if (!newCollection.images) {
          newCollection.images = [];
        }

        newCollection.images.push(file.name);

        await dispatch(updateCollection(newCollection));
      }

      dispatch(apiRequestReady(requestId));
    } catch (error) {
      Sentry.captureException(error);
      Sentry.captureMessage("Image upload failed.");
    }
  };
}

export const deleteImageSuccess = result => ({
  type: IMAGE_DELETE_SUCCESS,
  payload: result
});

export const deleteImageError = err => ({
  type: IMAGE_DELETE_ERROR,
  payload: err
});

export const deleteImage = image => async (dispatch, getState) => {
  request(dispatch, getState)(
    "deleteAdminImage",
    {
      pathParams: {
        image: image
      }
    },
    deleteImageSuccess,
    deleteImageError
  );
};
